body {
  font-family: "Roboto", sans-serif;
  background-color: black;
}

.Content {
  margin-bottom: 72px;
}

.appwrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.Loading {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0) !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.Load {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgb(0, 0, 0) !important;
}

.Logo {
  position: fixed;
  margin: 10px;
  z-index: 10;
  width: 200px;
}

.img {
  width: 20vh;
  margin: 10px;
}

.image {
  width: auto;
  height: 100vh;
}
.layer2Effect {
  mix-blend-mode: difference;
}
.screen {
  max-width: 100000px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;
}
.photowrapper {
  display: flex;
  flex-direction: row;
  width: 500000px;
  height: 100%;
  animation: "moveSlideshow 6s linear infinite";
}
@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.666%);
  }
}
.layer2 {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0px;
  width: 500000px;
  height: 100vh;
  animation: "moveSlideshow 6s linear infinite";
}
/* .name h1 {
  font-size: 1000px;
  font-weight: 900;
  color: aliceblue;
  opacity: 0.4;
  text-transform: uppercase; */
/* } */
/* .name {
  position: absolute;
  z-index: 10;
} */

.imagewrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.speedometer {
  position: fixed;
  /* z-index: 10; */
  bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.speidometerButton {
  padding: 10px 10px;
  background-color: white;
  margin: 0 2px;
  display: flex;
}

.slider {
  width: 40vw;
  border-radius: 50px;
  position: fixed;
  margin-bottom: 30vh;
  height: 30px;
  outline: 3px;
  box-shadow: 5px 2px 2px #393939, -5px -2px 2px #ffffffc1;
}

.example-thumb {
  height: 30px;
  width: 30px;
  background-image: url("../assets/Buttons/SpeedometerIcon.png");
  background-color: #ffffff3d;
  border-radius: 100px;
  /* width: 0; 
  height: 0; 
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 50px solid #ffffff;
  align-self: center;  */
}
.NavBar {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  z-index: 200;
  border-top: 1px solid rgb(0, 0, 0);
}

@media (max-width: 780px) {
  .Navbr {
    background-color: rgba(0, 0, 0, 0.8);
    height: 70px;
  }
}

.NavigationItems {
  height: 100%;
  display: flex;
  width: 95vw;
  align-items: center;
  position: relative;
  z-index: 20;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

/* @media (max-height: 780px) {
  .NavigationItems {
    width: 100vw;
  }
} */

.NavigationItem {
  height: 100%;
  display: flex;
  z-index: 30;
  color: white;
}

@media (max-height: 780px) {
  .NavigationItem {
    width: 18vw;
    align-self: center;
    margin-left: 0;
  }
}

.Patreon {
  height: 30px;
  width: 30px;
  background-size: 100%;
  background-image: url("../assets/Logo/PatreonLogo.svg");
}

.Patreon:hover {
  height: 30px;
  width: 30px;
  background-image: url("../assets/Logo/PatreonHoverLogo.svg");
}

.NavigationItem a {
  color: white;
  text-decoration: none;
  height: 100%;
  padding: 16px 10px;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active {
  color: rgb(213, 17, 164);
  position: relative;
}

@media (hover: none) {
  a:active {
    color: rgb(213, 17, 164);
  }
}

.Modal {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 80vh;
  bottom: 70px;
  transform: translateX(-100vw);
  transition: all 1s;
  overflow: hidden;
}

.ModalOpen {
  transform: translateX(0px);
}

@media (max-width: 780px) {
  .Modal {
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@media (max-height: 1000px) {
  .Modal {
    width: 100vw;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
  }
}

.Info {
  position: relative;
  z-index: 1;
  color: white;
  display: flex;
  width: 97vw;
  margin: 20px 20px;
  text-align: left;
}

@media (max-width: 780px) {
  .Info {
    flex-direction: column;
    overflow: hidden;
    height: 80vh;
    overflow-y: scroll;
    margin: 10px;
  }
}

@media (min-height: 780px) {
  .Info {
    overflow: hidden;
    overflow-y: scroll;
  }
}

.AboutMe {
  height: 30px;
}

.AboutMe p {
  text-align: justify;
}

.Artist {
  margin: 0 20px;
  padding: 16px;
  width: 50%;
  height: 80vh;
}

@media (max-width: 780px) {
  .Artist {
    width: 80%;
    height: 780px;
  }
}

@media (max-height: 780px) {
  .Artist {
    height: 400px;
  }
}

.PersonalLinks {
  display: flex;
  height: 50px;
}

@media (max-width: 780px) {
  .PersonalLinks {
    height: 50px;
    position: absolute;
    align-items: center;
    align-self: center;
  }
}

.Insta {
  margin: 20px;
}

.LinkedIn {
  margin: 20px;
}

.Portfolio {
  margin: 20px;
}

.ProfilePicture {
  border-radius: 100%;
  width: 200px;
}

@media (max-width: 780px) {
  .ProfilePicture {
    width: 100px;
    margin: 10px;
  }
}

.AboutProject {
  min-width: 300px;
  margin-left: 15px;
}

@media (max-width: 780px) {
  .AboutProject {
    width: 100%;
    height: 100%;
  }
}

.AboutProject a {
  color: white;
}

.AboutProject a:hover,
.AboutProject a:active {
  color: #d511a4;
}

.Project {
  margin: 0 20px;
  padding: 20px;
  width: 50%;
  border-left: 1px solid rgba(89, 89, 89, 0.817);
  border-top: none;
  height: 80vh;
}

.Project p {
  text-align: justify;
}

@media (max-width: 780px) {
  .Project {
    border-left: none;
    width: 80%;
    padding: 20px;
    position: relative;
    margin-top: 300px;
    margin-left: 0px;
  }
}

.TrumanBrewery {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.SignatureArtPrize {
  width: 100%;
  margin-top: 20px;
}

.Strolls {
  width: 100%;
  margin-top: 20px;
}